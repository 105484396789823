export default {
	1000: {
		早餐: [
			['主食（克）', 25.0, 85.0],
			['牛奶（毫升）', 250.0, 138.0],
			['鸡蛋（个）', 15.0, 20.0],
			['蔬菜（克）', 50.0, 12.0],
		],
		午餐: [
			['主食（克）', 60.0, 216.0],
			['肉鱼（克）', 25.0, 32.0],
			['蔬菜（克）', 200.0, 46.0],
			['豆腐（克）', 50.0, 39.0],
		],
		晚餐: [
			['主食（克）', 50.0, 170.0],
			['肉鱼（克）', 150.0, 34.0],
			['蔬菜（克）', 20.0, 25.6],
		],
		加餐: [['水果（克）', 150.0, 66.0]],
		其他: [
			['用油（克）', 13.0, 116.0],
			['食盐（克）', 6.0, 0],
			['水（毫升）', 1500.0, 0],
		],
	},
	1100: {
		早餐: [
			['主食（克）', 30.0, 102.0],
			['牛奶（毫升）', 250.0, 138.0],
			['鸡蛋（个）', 15.0, 20.0],
			['蔬菜（克）', 50.0, 12.0],
		],
		午餐: [
			['主食（克）', 70.0, 252.0],
			['肉鱼（克）', 30.0, 38.0],
			['蔬菜（克）', 200.0, 46.0],
			['豆腐（克）', 75.0, 61.0],
		],
		晚餐: [
			['主食（克）', 50.0, 170.0],
			['肉鱼（克）', 25.0, 32.0],
			['蔬菜（克）', 150.0, 34.0],
		],
		加餐: [['水果（克）', 150.0, 66.0]],
		其他: [
			['用油（克）', 14.0, 129.0],
			['食盐（克）', 6.0, 0],
			['水（毫升）', 1500.0, 0],
		],
	},
	1200: {
		早餐: [
			['主食（克）', 35.0, 119.0],
			['牛奶（毫升）', 250.0, 138.0],
			['鸡蛋（个）', 30.0, 44.0],
			['蔬菜（克）', 50.0, 12.0],
		],
		午餐: [
			['主食（克）', 75.0, 273.0],
			['肉鱼（克）', 35.0, 44.0],
			['蔬菜（克）', 200.0, 46.0],
			['豆腐（克）', 75.0, 61.0],
		],
		晚餐: [
			['主食（克）', 55.0, 187.0],
			['肉鱼（克）', 25.0, 32.0],
			['蔬菜（克）', 150.0, 34.0],
		],
		加餐: [['水果（克）', 200.0, 88.0]],
		其他: [
			['用油（克）', 14.0, 122.0],
			['食盐（克）', 6.0, 0],
			['水（毫升）', 1500.0, 0],
		],
	},
	1300: {
		早餐: [
			['主食（克）', 40.0, 136.0],
			['牛奶（毫升）', 250.0, 138.0],
			['鸡蛋（个）', 30.0, 88.0],
			['蔬菜（克）', 50.0, 12.0],
		],
		午餐: [
			['主食（克）', 75.0, 270.0],
			['肉鱼（克）', 40.0, 50.0],
			['蔬菜（克）', 200.0, 46.0],
			['豆腐（克）', 75.0, 61.0],
		],
		晚餐: [
			['主食（克）', 60.0, 204.0],
			['肉鱼（克）', 25.0, 32.0],
			['蔬菜（克）', 200.0, 45.0],
		],
		加餐: [['水果（克）', 200.0, 66.0]],
		其他: [
			['用油（克）', 17.0, 152.0],
			['食盐（克）', 6.0, 0],
			['水（毫升）', 1500.0, 0],
		],
	},
	1400: {
		早餐: [
			['主食（克）', 45.0, 153.0],
			['牛奶（毫升）', 250.0, 138.0],
			['鸡蛋（个）', 60.0, 88.0],
			['蔬菜（克）', 50.0, 12.0],
		],
		午餐: [
			['主食（克）', 90.0, 306.0],
			['肉鱼（克）', 40.0, 50.0],
			['蔬菜（克）', 200.0, 46.0],
			['豆腐（克）', 75.0, 61.0],
		],
		晚餐: [
			['主食（克）', 65.0, 221.0],
			['肉鱼（克）', 30.0, 38.4],
			['蔬菜（克）', 200.0, 58.0],
		],
		加餐: [['水果（克）', 200.0, 88.0]],
		其他: [
			['用油（克）', 16.0, 141.0],
			['食盐（克）', 6.0, 0],
			['水（毫升）', 1500.0, 0],
		],
	},
	1500: {
		早餐: [
			['主食（克）', 50.0, 170.0],
			['牛奶（毫升）', 250.0, 138.0],
			['鸡蛋（个）', 60.0, 88.0],
			['蔬菜（克）', 100.0, 23.0],
		],
		午餐: [
			['主食（克）', 100.0, 341.0],
			['肉鱼（克）', 50.0, 64.0],
			['蔬菜（克）', 250.0, 58.0],
			['豆腐（克）', 50.0, 40.0],
		],
		晚餐: [
			['主食（克）', 70.0, 256.0],
			['肉鱼（克）', 35.0, 63.0],
			['蔬菜（克）', 200.0, 58.0],
		],
		加餐: [['水果（克）', 150.0, 66.0]],
		其他: [
			['用油（克）', 15.0, 135.0],
			['食盐（克）', 6.0, 0],
			['水（毫升）', 1500.0, 0],
		],
	},
	1600: {
		早餐: [
			['主食（克）', 55.0, 187.0],
			['牛奶（毫升）', 250.0, 138.0],
			['鸡蛋（个）', 60.0, 88.0],
			['蔬菜（克）', 100.0, 23.0],
		],
		午餐: [
			['主食（克）', 100.0, 341.0],
			['肉鱼（克）', 75.0, 96.0],
			['蔬菜（克）', 250.0, 58.0],
			['豆腐（克）', 50.0, 40.0],
		],
		晚餐: [
			['主食（克）', 50.0, 221.0],
			['肉鱼（克）', 50.0, 63.0],
			['蔬菜（克）', 200.0, 58.0],
		],
		加餐: [
			['水果（克）', 150.0, 66.0],
			['点心', 15.0, 61.0],
		],
		其他: [
			['用油（克）', 18.0, 160.0],
			['食盐（克）', 6.0, 0],
			['水（毫升）', 1500.0, 0],
		],
	},
	1700: {
		早餐: [
			['主食（克）', 60.0, 204.0],
			['牛奶（毫升）', 250.0, 138.0],
			['鸡蛋（个）', 60.0, 88.0],
			['蔬菜（克）', 100.0, 23.0],
		],
		午餐: [
			['主食（克）', 100.0, 341.0],
			['肉鱼（克）', 75.0, 94.0],
			['蔬菜（克）', 250.0, 58.0],
			['豆腐（克）', 75.0, 61.0],
		],
		晚餐: [
			['主食（克）', 75.0, 256.0],
			['肉鱼（克）', 50.0, 63.0],
			['蔬菜（克）', 200.0, 58.0],
		],
		加餐: [['水果（克）', 150.0, 66.0]],
		其他: [
			['用油（克）', 28.0, 250.0],
			['食盐（克）', 6.0, 0],
			['水（毫升）', 1500.0, 0],
		],
	},
	1800: {
		早餐: [
			['主食（克）', 75.0, 256.0],
			['牛奶（毫升）', 250.0, 138.0],
			['鸡蛋（个）', 60.0, 88.0],
			['蔬菜（克）', 100.0, 23.0],
		],
		午餐: [
			['主食（克）', 100.0, 341.0],
			['肉鱼（克）', 75.0, 94.0],
			['蔬菜（克）', 250.0, 58.0],
			['豆腐（克）', 100.0, 81.0],
		],
		晚餐: [
			['主食（克）', 100.0, 341.0],
			['肉鱼（克）', 50.0, 63.0],
			['蔬菜（克）', 250.0, 58.0],
		],
		加餐: [['水果（克）', 200.0, 88.0]],
		其他: [
			['用油（克）', 19.0, 171.0],
			['食盐（克）', 6.0, 0],
			['水（毫升）', 1500.0, 0],
		],
	},
	1900: {
		早餐: [
			['主食（克）', 100.0, 341.0],
			['牛奶（毫升）', 250.0, 138.0],
			['鸡蛋（个）', 60.0, 88.0],
			['蔬菜（克）', 100.0, 23.0],
		],
		午餐: [
			['主食（克）', 100.0, 341.0],
			['肉鱼（克）', 75.0, 94.0],
			['蔬菜（克）', 250.0, 58.0],
			['豆腐（克）', 100.0, 81.0],
		],
		晚餐: [
			['主食（克）', 100.0, 341.0],
			['肉鱼（克）', 50.0, 63.0],
			['蔬菜（克）', 250.0, 58.0],
		],
		加餐: [['水果（克）', 200.0, 88.0]],
		其他: [
			['用油（克）', 21.0, 186.0],
			['食盐（克）', 6.0, 0],
			['水（毫升）', 1500.0, 0],
		],
	},
	2000: {
		早餐: [
			['主食（克）', 50.0, 341.0],
			['牛奶（毫升）', 250.0, 138.0],
			['鸡蛋（个）', 60.0, 88.0],
			['蔬菜（克）', 100.0, 23.0],
		],
		午餐: [
			['主食（克）', 100.0, 341.0],
			['肉鱼（克）', 75.0, 94.0],
			['蔬菜（克）', 250.0, 58.0],
			['豆腐（克）', 100.0, 81.0],
		],
		晚餐: [
			['主食（克）', 100.0, 341.0],
			['肉鱼（克）', 50.0, 63.0],
			['蔬菜（克）', 250.0, 58.0],
		],
		加餐: [
			['水果（克）', 200.0, 88.0],
			['点心', 20.0, 87.0],
		],
		其他: [
			['用油（克）', 20.0, 199.0],
			['食盐（克）', 6.0, 0],
			['水（毫升）', 1500.0, 0],
		],
	},
	2100: {
		早餐: [
			['主食（克）', 100.0, 341.0],
			['牛奶（毫升）', 250.0, 138.0],
			['鸡蛋（个）', 60.0, 88.0],
			['蔬菜（克）', 50.0, 12.0],
		],
		午餐: [
			['主食（克）', 150.0, 512.0],
			['肉鱼（克）', 100.0, 126.0],
			['蔬菜（克）', 250.0, 58.0],
			['豆腐（克）', 100.0, 81.0],
		],
		晚餐: [
			['主食（克）', 100.0, 341.0],
			['肉鱼（克）', 50.0, 63.0],
			['蔬菜（克）', 250.0, 58.0],
		],
		加餐: [['水果（克）', 200.0, 88.0]],
		其他: [
			['用油（克）', 22.0, 194.0],
			['食盐（克）', 6.0, 0],
			['水（毫升）', 1500.0, 0],
		],
	},
	2200: {
		早餐: [
			['主食（克）', 50.0, 341.0],
			['牛奶（毫升）', 250.0, 138.0],
			['鸡蛋（个）', 60.0, 88.0],
			['蔬菜（克）', 50.0, 12.0],
		],
		午餐: [
			['主食（克）', 150.0, 512.0],
			['肉鱼（克）', 75.0, 94.0],
			['蔬菜（克）', 250.0, 58.0],
			['豆腐（克）', 100.0, 81.0],
		],
		晚餐: [
			['主食（克）', 100.0, 341.0],
			['肉鱼（克）', 75.0, 94.0],
			['蔬菜（克）', 250.0, 58.0],
		],
		加餐: [
			['水果（克）', 200.0, 88.0],
			['点心', 20.0, 87.0],
		],
		其他: [
			['用油（克）', 23.0, 208.0],
			['食盐（克）', 6.0, 0],
			['水（毫升）', 1500.0, 0],
		],
	},
	2300: {
		早餐: [
			['主食（克）', 100.0, 341.0],
			['牛奶（毫升）', 250.0, 138.0],
			['鸡蛋（个）', 60.0, 88.0],
			['蔬菜（克）', 50.0, 12.0],
		],
		午餐: [
			['主食（克）', 150.0, 512.0],
			['肉鱼（克）', 100.0, 126.0],
			['蔬菜（克）', 250.0, 58.0],
			['豆腐（克）', 100.0, 81.0],
		],
		晚餐: [
			['主食（克）', 102.0, 426.0],
			['肉鱼（克）', 75.0, 94.0],
			['蔬菜（克）', 250.0, 58.0],
		],
		加餐: [
			['水果（克）', 200.0, 88.0],
			['点心', 20.0, 87.0],
		],
		其他: [
			['用油（克）', 21.0, 191.0],
			['食盐（克）', 6.0, 0],
			['水（毫升）', 1500.0, 0],
		],
	},
	2400: {
		早餐: [
			['主食（克）', 125.0, 426.0],
			['牛奶（毫升）', 250.0, 138.0],
			['鸡蛋（个）', 60.0, 88.0],
			['蔬菜（克）', 50.0, 12.0],
		],
		午餐: [
			['主食（克）', 150.0, 512.0],
			['肉鱼（克）', 100.0, 126.0],
			['蔬菜（克）', 250.0, 58.0],
			['豆腐（克）', 100.0, 81.0],
		],
		晚餐: [
			['主食（克）', 102.0, 426.0],
			['肉鱼（克）', 100.0, 126.0],
			['蔬菜（克）', 250.0, 58.0],
		],
		加餐: [
			['水果（克）', 200.0, 88.0],
			['点心', 15.0, 65.0],
		],
		其他: [
			['用油（克）', 22.0, 196.0],
			['食盐（克）', 6.0, 0],
			['水（毫升）', 1500.0, 0],
		],
	},
};
