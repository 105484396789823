<template>
  <div class="plan-content-container">
    <div class="plan-content-wrapper">
      <div class="plan-content-header">
        <div class="plan-content-header_img-wrapper">
          <img class="plan-content-header_img" src="@/assets/img/report-bg.jpg" alt="background">
        </div>
        <div class="plan-content-header_title">健康行为目标指南</div>
        <div class="plan-content-header_text">
          <span>姓名：{{user.name}}</span>
          <span>性别：{{user['sex_show']}}</span>
          <span>年龄：{{user.age}}</span>
          <span>门店：{{user.md}}</span>
        </div>
      </div>
      <div class="plan-content-body">
        <div class="level-result-field">
          <img src="@/assets/img/a1b1.jpg" alt="pic">
          <div class="show-result">
            <table class="result-table">
              <tr class="result-table--tr">
                <td :class="['result-table--td', behavior.fx === 'A2' ? 'result-table--td-checked': '']">A2</td>
                <td :class="['result-table--td', behavior.fx === 'A2 B1' ? 'result-table--td-checked': '']">A2 B1</td>
                <td :class="['result-table--td', behavior.fx === 'A2 B2' ? 'result-table--td-checked': '']">A2 B2</td>
              </tr>
              <tr class="result-table--tr">
                <td :class="['result-table--td', behavior.fx === 'A1' ? 'result-table--td-checked': '']">A1</td>
                <td :class="['result-table--td', behavior.fx === 'A1 B1' ? 'result-table--td-checked': '']">A1 B1</td>
                <td :class="['result-table--td', behavior.fx === 'A1 B2' ? 'result-table--td-checked': '']">A1 B2</td>
              </tr>
              <tr class="result-table--tr">
                <td :class="['result-table--td', behavior.fx === 'O' ? 'result-table--td-checked': '']">O</td>
                <td :class="['result-table--td', behavior.fx === 'B1' ? 'result-table--td-checked': '']">B1</td>
                <td :class="['result-table--td', behavior.fx === 'B2' ? 'result-table--td-checked': '']">B2</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="level-result-field">
          <span class="level-result-field_label">您 iTLC 训练等级评价为：</span>
          <span class="level-result_score">{{behavior.fx}}</span>
        </div>
        <div class="level-result-field">
          <span class="level-result-field_label">描述 : </span>
          <span class="level-result-field_text">{{behavior.ms}}</span>
        </div>
        <div class="level-result-field">
          <span class="level-result-field_label">特点 : </span>
          <span class="level-result-field_text">{{behavior.td}}</span>
        </div>
        <div class="level-result-field" style="display:flex;">
          <span class="level-result-field_label" style="margin-right:5px;">重点提示 : </span>
          <span class="level-result-field_text" v-html="replaceBreak(behavior.zdts)"></span>
        </div>
        <div class="level-result-field">
          <span class="level-result-field_label">健康综合能力进阶 : </span>
          <span class="level-result-field_text"> {{behavior.nljj}}</span>
        </div>
      </div>
      <div class="content-footer">
        <p>来源：《美国运动医学会PAR-Q问卷》、《运动生理学》、《群体行为分析学》等</p>
        <p>郑重说明：本页内容不能替代临床处方，仅用于健康行为促进</p>
      </div>
    </div>
  </div>
</template>

<script  type="text/ecmascript-6">
export default {
	props: {
		user: {
			type: Object,
		},
		data: {
			type: Object,
		},
		behavior: {
			type: Object,
		},
	},
	data() {
		return {};
	},
	methods: {
		replaceBreak(val) {
			return val.replace(/\r/g, '<br>');
		},
		mapClass(val, type = 'column') {
			if (val === -1) {
				return `target-${type}_pic target-column_pic--low`;
			} else if (val === 0) {
				return `target-${type}_pic target-column_pic--normal`;
			} else if (val === 1) {
				return `target-${type}_pic target-column_pic--high`;
			} else {
				return '';
			}
		},
	},
};
</script>

<style lang="less" scoped>
.plan-content-wrapper {
  .level-result_score {
    border-bottom: #4472c4 3px solid;
    font-size: 32px;
    padding: 0 10px;
    font-weight: 700;
    color: #e25127;
  }
  .level-result-field {
    margin: 15px 0;
    position: relative;
    &_label {
      color: #4472c4;
      font-weight: 700;
      font-size: 18px;
    }
    &_text {
      font-size: 18px;
    }
    .show-result {
      position: absolute;
      width: 338px;
      height: 299px;
      top: 46px;
      left: 41px;
      .result-table {
        width: 100%;
        height: 100%;
        table-layout: fixed;
        &--td {
          background-color: #306a81;
          font-size: 24px;
          color: #fff;
          text-align: center;
          border-right: 1px #fff solid;
          border-bottom: 1px #fff solid;
          &-checked {
            background-color: #e55530;
          }
        }
        &--tr:last-child {
          .result-table--td {
            border-bottom: none;
          }
        }
      }
    }
  }
}
</style>
