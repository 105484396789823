<template>
  <div class="plan-content-container">
    <div class="plan-content-wrapper">
      <div class="plan-content-header">
        <div class="plan-content-header_img-wrapper">
          <img class="plan-content-header_img" src="@/assets/img/report-bg.jpg" alt="background">
        </div>
        <div class="plan-content-header_title">适生®完整性营养补充方案(一)</div>
        <div class="plan-content-header_text">
          <span>姓名：{{user.name}}</span>
          <span>性别：{{user['sex_show']}}</span>
          <span>年龄：{{user.age}}</span>
          <span>门店：{{user.md}}</span>
        </div>
      </div>
      <div class="plan-content-body">
        <div class="nutrition-header">
          <div class="nutrition-header-left">
            根据您的基本信息计算，BMI: {{data.bmi}}<br>我们推荐您每日摄入量为：
          </div>
          <div class="nutrition-header-right">
            <div class="nutrition-text">{{data.srl}}</div>
            <span style="font-size:24px;display:inline;margin-left:10px;">kcal</span>
          </div>
        </div>
        <Row class="graph-panel">
          <Col span="12">
            <Row>
              <Col span="6"><div class="graph-title">建议营养比</div></Col>
              <Col span="18"><div class="nutrition-chart" ref="left"></div></Col>
            </Row>
            <Row>
              <Col span="6"><div class="graph-title">建议三餐比</div></Col>
              <Col span="18"><div class="nutrition-chart" ref="right"></div></Col>
            </Row>
          </Col>
          <Col span="12">
            <div style="text-align:center;">
              <img src="@/assets/img/food-tower.png" style="width: 85%;margin-top: 50px;">
            </div>
          </Col>
        </Row>
        <Form class="need-remove"
              :label-width="85"
             >
          <Form-item label="配餐模式：">
            <RadioGroup v-model="type">
              <Radio label="固定配餐"></Radio>
              <Radio label="手动配餐"></Radio>
            </RadioGroup>
            <span style="color:#96999e;font-size:14px;vertical-align:middle;">手动配餐模式下，点击“食物类别”标题，可以自行添加食物；点击每行，可以删除食物。</span>
          </Form-item>
        </Form>
        <p class="nutrition-tips">推荐每日食谱：{{index}} Kcal</p>
        <table class="nutrition-data-table">
          <tr>
            <th>餐次</th>
            <th :style="type === '手动配餐'? 'cursor:pointer': ''" @click="handleClickTitle">食物类别</th>
            <th>重量</th>
            <th>能量（Kcal）</th>
          </tr>
          <template v-for="(value, key) in nutritionData">
            <tr v-for="(row, index) in value" :key="key + index">
              <td v-if="index == 0" :rowspan="value.length">{{key}}</td>
              <td v-for="(text, i) in row" :key="i" @click="handleClickRow(key, index)">{{text}}</td>
            </tr>
          </template>
        </table>
        <Modal
            v-model="showModel"
            title="手动配餐"
            @on-ok="onOk"
            @on-cancel="onCancel">
          <Form :label-width="115" label-position="right">
            <Form-item label="餐次：">
              <Select v-model="temp.time" style="width: 100px">
                <Option value="早餐">早餐</Option>
                <Option value="午餐">午餐</Option>
                <Option value="晚餐">晚餐</Option>
                <Option value="加餐">加餐</Option>
                <Option value="其他">其他</Option>
              </Select>
            </Form-item>
            <Form-item label="食物类别：">
              <Input v-model="temp.food" placeholder="请输入食物类别" style="width: 300px" />
            </Form-item>
            <Form-item label="重量：">
              <Input v-model="temp.weight" placeholder="请输入重量" style="width: 300px" />
            </Form-item>
            <Form-item label="能量：">
              <Input v-model="temp.kcal" placeholder="请输入能量" style="width: 300px" />
            </Form-item>
          </Form>
        </Modal>
      </div>
      <div class="content-footer">
        <p>来源：《美国运动医学会PAR-Q问卷》、《运动生理学》、《群体行为分析学》等</p>
        <p>郑重说明：本页内容不能替代临床处方，仅用于健康行为促进</p>
      </div>
    </div>
  </div>
</template>

<script  type="text/ecmascript-6">
import echarts from 'echarts';
import nutrition from './nutrition';

export default {
	props: {
		user: {
			type: Object,
		},
		data: {
			type: Object,
		},
	},
	data() {
		return {
			type: '固定配餐',
			showModel: false,
			temp: {
				time: '早餐',
				food: '',
				weight: '',
				kcal: '',
			},
			index: 2000,
			nutritionData: [],
		};
	},
	mounted() {
		const number = Math.round(this.data.srl / 100) * 100;
		if (number >= 1000 && number <= 2400) {
			this.index = number;
		}
		this.nutritionData = nutrition[`${this.index}`];

		function commonSingleOpt(legend, data) {
			const option = {
				legend: {
					orient: 'vertical',
					y: 'center',
					right: 10,
					itemWidth: 10,
					itemHeight: 10,
					selectedMode: false,
					data: legend,
				},
				color: ['#4472c4', '#f27a74', '#41bc79', '#c5e0b4'],
				series: [
					{
						name: '占比',
						type: 'pie',
						hoverAnimation: false,
						radius: '80%',
						center: ['40%', '50%'],
						label: {
							formatter: '{d}%',
							color: '#fff',
							backgroundColor: '#404040',
							padding: 5,
						},
						labelLine: {
							length: -5,
							length2: 0,
						},
						data: data,
					},
				],
			};
			return option;
		}
		const leftData = [
			{ value: this.data.yypb.ts, name: '碳水' },
			{ value: this.data.yypb.dbz, name: '蛋白质' },
			{ value: this.data.yypb.zf, name: '脂肪' },
		];
		const rightData = [
			{ value: 27, name: '早餐' },
			{ value: 36, name: '午餐' },
			{ value: 27, name: '晚餐' },
			{ value: 10, name: '加餐' },
		];
		echarts.init(this.$refs.left).setOption(
			commonSingleOpt(
				leftData.map((data) => data.name),
				leftData,
			),
		);
		echarts.init(this.$refs.right).setOption(
			commonSingleOpt(
				rightData.map((data) => data.name),
				rightData,
			),
		);
	},
	methods: {
		handleClickTitle() {
			if (this.type === '手动配餐') {
				this.showModel = true;
			}
		},
		handleClickRow(food, index) {
			if (this.type === '手动配餐') {
				this.$Modal.confirm({
					content: '确定删除所选明细吗？',
					onOk: () => {
						this.$nextTick(() => {
							this.nutritionData[food].splice(index, 1);
						});
					},
				});
			}
		},
		onOk() {
			this.nutritionData[this.temp.time].push(Object.values(this.temp).splice(1));
		},
		onCancel() {
			this.showModel = false;
		},
	},
};
</script>

<style lang="less" scoped>
.plan-content-body {
  .nutrition-header {
    margin-top: 15px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    &-left {
      margin-top: 15px;
    }
    .nutrition-text {
      display: inline-block;
      background: url(@/assets/img/nutrition-border.png) no-repeat left;
      width: 220px;
      height: 84px;
      line-height: 84px;
      margin-left: 50px;
      text-align: center;
      font-weight: 700;
      font-size: 32px;
    }
  }
  .nutrition-tips {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
  }
  .graph-title {
    background-color: #5b9bd5;
    border-radius: 6px;
    color: white;
    width: 80%;
    margin: 0 auto;
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin-top: 65px;
  }
  .nutrition-chart {
    width: 100%;
    height: 200px;
  }
  .nutrition-data-table {
    width: 100%;
    th {
      border: #000 solid 2px;
      background-color: #5b9bd5;
      height: 40px;
      color: #fff;
    }
    td {
      border: #000 solid 2px;
      height: 30px;
      text-align: center;
    }
  }
}
.ivu-form-item {
  margin-bottom: 0;
}
</style>
