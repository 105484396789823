<template>
  <div class="plan-content-container">
    <div class="plan-content-wrapper">
      <div class="plan-content-header">
        <div class="plan-content-header_img-wrapper">
          <img class="plan-content-header_img" src="@/assets/img/report-bg.jpg" alt="background">
        </div>
        <div class="plan-content-header_title">适生®适应性强化训练方案(三)</div>
        <div class="plan-content-header_text">
          <span>姓名：{{user.name}}</span>
          <span>性别：{{user['sex_show']}}</span>
          <span>年龄：{{user.age}}</span>
          <span>门店：{{user.md}}</span>
        </div>
      </div>
      <div class="plan-content-body">
        <Form class="need-remove"
              :label-width="85"
             >
          <Form-item label="选择方案：">
            <Select v-model="selectedItems" multiple style="width:350px">
              <Option v-for="item in data" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
            <Button type="primary" @click="handleConfirm">确定</Button>
            <span style="color:#96999e;font-size:14px;margin-left:15px;">注意：最多只能选择三个方案</span>
          </Form-item>
        </Form>
        <div class="recovery-data-wrapper" v-for="(item, i) in result" :key="i">
          <div class="recovery-data">
            <div class="recovery-data_title">{{item.name}}</div>
            <p class="recovery-data_tip">训练部位 Training parts</p>
            <div class="recovery-data_text">{{item.xlbw}}</div>
            <p class="recovery-data_tip">训练作用 Training function</p>
            <div class="recovery-data_text" v-html="replaceBreak(item.zygn)"></div>
          </div>
          <img :src="item.tp" alt="pics" class="recovery-img">
        </div>
      </div>
      <div class="content-footer">
        <p>来源：《功能性训练》、《中⽼年⼈⼒量训练指南》、《运动⼈体科学概论》等</p>
        <p>郑重说明：本⻚内容不能替代临床处⽅，仅⽤于健康行为促进</p>
      </div>
    </div>
  </div>
</template>

<script  type="text/ecmascript-6">
export default {
	props: {
		user: {
			type: Object,
		},
		data: {
			type: Array,
		},
	},
	data() {
		return {
			selectedItems: [],
			result: [],
		};
	},
	methods: {
		replaceBreak(val) {
			return val.replace(/\r/g, '<br>').replace(/ /g, '&ensp;');
		},
		handleConfirm() {
			if (this.selectedItems.length === 0) {
				this.$Message.warning('请先选择方案');
				return;
			}
			this.result = [];
			if (this.selectedItems.length < 4) {
				for (let i = 0; i < this.data.length; i++) {
					for (let j = 0; j < this.selectedItems.length; j++) {
						if (this.data[i].id === this.selectedItems[j]) {
							this.result.push(this.data[i]);
						}
					}
				}
			} else {
				this.$Message.error('最多只能选择三个方案');
			}
		},
	},
};
</script>

<style lang="less" scoped>
.need-remove {
  margin-top: 15px;
}
.recovery-data-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding: 0 55px 30px;
  border-bottom: #4472c4 dashed 2px;
}
.recovery-data {
  width: 320px;
  font-size: 16px;
  &_title {
    background-color: #4472c4;
    border-radius: 4px;
    color: #fff;
    height: 32px;
    line-height: 32px;
    text-align: center;
    width: 200px;
    margin: 15px 0;
  }
  &_tip {
    color: #4472c4;
    font-weight: 700;
    margin-top: 15px;
  }
  &_text {
    margin-top: 10px;
  }
}
.recovery-img {
  max-width: 420px;
  max-height: 270px;
  margin-right: 50px;
}
</style>
