<template>
  <div class="new-plan-container">
    <v-title>
      <Breadcrumb>
        <Breadcrumb-item>
          <i class="icon-home"></i>方案管理</Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <div class="content">
      <div class="search-box">
        <Row>
          <Col span="24">
          <h2 class="search-title border-bottom">按条件生成方案</h2>
          </Col>
        </Row>
        <Form class="search-form"
              :label-width="85"
             >
          <Form-item label="选择对象" v-if="$route.params.id === 'all'">
            <Row>
              <Col span="5">
              <span class="sub-label">省份：</span>
              <div class="sub-content">
                <Select clearable
                        v-model="searchData.pid"
                        placeholder="请选择..."
                        @on-change="changePro">
                  <Option :value="v.id"
                          v-for="v in proList"
                          :key="v.id">{{v.name}}</Option>
                </Select>
              </div>
              </Col>
              <Col span="5"
                   offset="1">
              <span class="sub-label">城市：</span>
              <div class="sub-content">
                <Select clearable
                        v-model="searchData.cid"
                        placeholder="请选择..."
                        @on-change="changeCity">
                  <Option :value="v.id"
                          v-for="v in cityList"
                          :key="v.id">{{v.name}}</Option>
                </Select>
              </div>
              </Col>
              <Col span="5"
                   offset="1">
              <span class="sub-label">门店：</span>
              <div class="sub-content">
                <Select clearable
                        v-model="searchData.sid"
                        placeholder="请选择..."
                        @on-change="changeStore">
                  <Option :value="v.id"
                          v-for="v in storeList"
                          :key="v.id">{{v.name}}</Option>
                </Select>
              </div>
              </Col>
              <Col span="5"
                   offset="1">
              <span class="sub-label">会员：</span>
              <div class="sub-content">
                <Select clearable
                        v-model="uid"
                        placeholder="请选择..."
                        filterable>
                  <Option :value="v.id"
                          v-for="v in userList"
                          :key="v.id">{{v.name}}</Option>
                </Select>
              </div>
              </Col>
            </Row>
          </Form-item>
          <Form-item label="生成项目">
            <Checkbox :indeterminate="indeterminate" :value="checkAll" @click.prevent.native="handleCheckAll">全选</Checkbox>
          </Form-item>
          <Form-item label="">
            <CheckboxGroup v-model="selectedProjects" @on-change="checkAllGroupChange">
              <Checkbox :label="v" v-for="(v, i) in allProjects" :key="i"></Checkbox>
            </CheckboxGroup>
          </Form-item>
          <Form-item label="">
            <Button @click="generatePlan">生成指导方案</Button>
            <Button @click="generateReport">生成报告</Button>
          </Form-item>
        </Form>
      </div>
      <div class="content-box">
        <h2 class="content-title border-bottom">方案预览</h2>
        <div class="breadcrumbs-bar">
          <span
            :class="['breadcrumb', {'active': i === selectedIndex}]"
            v-for="(v, i) in cacheProjects"
            :key="i"
            @click="clickBreadcrumb(i)"
          >{{v}}</span>
        </div>
        <div class="report-content" ref="pdfContent">
          <div style="padding:20px 0;text-align:center;" v-if="cacheProjects.length === 0">暂无内容，请先生成指导方案，确认无误后再生成报告。</div>
          <report-cover v-if="cacheProjects.includes('封面')" :user="userData" :data="fmdz"></report-cover>
          <report-questionnaire v-if="cacheProjects.includes('问卷')" :user="userData" :data="questionOne" :question="questionTwo"></report-questionnaire>
          <report-filter v-if="cacheProjects.includes('健康风险评估')" :user="userData" :data="filterData"></report-filter>
          <report-target-body v-if="cacheProjects.includes('健康生理目标指南')" :user="userData" :data="targetData" :behavior="behaviorData"></report-target-body>
          <report-target-behavior v-if="cacheProjects.includes('健康行为目标指南')" :user="userData" :data="targetData" :behavior="behaviorData"></report-target-behavior>
          <report-nutrition v-if="cacheProjects.includes('适生®方案')" :user="userData" :data="nutritionData"></report-nutrition>
          <report-lose v-if="cacheProjects.includes('适生®方案')" :user="userData" :data="loseData" :selectItems="dxk"></report-lose>
          <report-train v-if="cacheProjects.includes('适生®方案')" :user="userData" :data="trainData"></report-train>
          <report-recovery v-if="cacheProjects.includes('适生®方案')" :user="userData" :data="recoveryData" :data2="recoveryData2"></report-recovery>
          <report-action-plan v-if="cacheProjects.includes('执行方案汇总')" :user="userData" :data="actionPlan"></report-action-plan>
          <report-action-week v-if="cacheProjects.includes('执行促进周计划')" :user="userData" :data="actionWeekData"></report-action-week>
          <report-health-manage v-if="cacheProjects.includes('健康管理执行计划')" :user="userData" :data="healthManageData" :behavior="behaviorData"></report-health-manage>
          <report-back-cover v-if="cacheProjects.includes('封底')" :user="userData" :data="fddz"></report-back-cover>
        </div>
      </div>
    </div>
  </div>
</template>

<script  type="text/ecmascript-6">
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import vTitle from 'components/topHeader/topHeader';
import ReportFilter from './report-filter';
import ReportTargetBody from './report-target-body';
import ReportTargetBehavior from './report-target-behavior';
import ReportNutrition from './report-nutrition';
import ReportLose from './report-lose';
import ReportTrain from './report-train';
import ReportRecovery from './report-recovery';
import ReportActionPlan from './report-action-plan';
import ReportActionWeek from './report-action-week';
import ReportQuestionnaire from './report-questionnaire';
import ReportCover from './report-cover';
import ReportBackCover from './report-back-cover';
import ReportHealthManage from './report-health-manage';
import qualityService from '@/services/qualityService';
import planService from '@/services/planService';

export default {
	components: {
		'v-title': vTitle,
		ReportFilter,
		ReportTargetBody,
		ReportTargetBehavior,
		ReportNutrition,
		ReportLose,
		ReportTrain,
		ReportRecovery,
		ReportActionPlan,
		ReportActionWeek,
		ReportQuestionnaire,
		ReportCover,
		ReportBackCover,
		ReportHealthManage,
	},
	data() {
		return {
			searchData: {
				page: 1,
				size: 1000,
				pid: '',
				cid: '',
				sid: '',
			},
			storeList: [],
			proList: [],
			cityList: [],
			userList: [],
			uid: '',
			allProjects: [
				'封面',
				'问卷',
				'健康风险评估',
				'健康生理目标指南',
				'健康行为目标指南',
				'适生®方案',
				'执行方案汇总',
				'执行促进周计划',
				'健康管理执行计划',
				'封底',
			],
			clickIndex: 0,
			scrollFunc: null,
			scrollPage: null,
			selectedProjects: [],
			cacheProjects: [],
			hasPlan: false,
			selectedIndex: 0,
			userData: {},
			targetData: {},
			behaviorData: {},
			loseData: {},
			trainData: [],
			recoveryData: [],
			recoveryData2: [],
			nutritionData: {},
			filterData: {},
			actionWeekData: [],
			actionPlan: [],
			questionOne: {},
			questionTwo: {},
			healthManageData: [],
			indeterminate: false,
			checkAll: false,
			fmdz: '',
			fddz: '',
		};
	},
	created() {
		qualityService.getQualitySelect().then((data) => {
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
		});
	},
	mounted() {
		this.scrollPage = function (offsetArr, self) {
			const scrollTop = document.documentElement.scrollTop;
			switch (true) {
				case scrollTop < offsetArr[0]:
					self.clickIndex = 0;
					break;
				case scrollTop < offsetArr[1]:
					self.clickIndex = 1;
					break;
				case scrollTop < offsetArr[2]:
					self.clickIndex = 2;
					break;
				case scrollTop < offsetArr[3]:
					self.clickIndex = 3;
					break;
				case scrollTop < offsetArr[4]:
					self.clickIndex = 4;
					break;
				default:
					self.clickIndex = offsetArr.length;
			}
		};
	},
	destroyed() {
		window.removeEventListener('scroll', this.scrollFunc);
	},
	methods: {
		handleCheckAll() {
			if (this.indeterminate) {
				this.checkAll = false;
			} else {
				this.checkAll = !this.checkAll;
			}
			this.indeterminate = false;

			if (this.checkAll) {
				this.selectedProjects = this.allProjects;
			} else {
				this.selectedProjects = [];
			}
		},
		checkAllGroupChange(data) {
			if (data.length === 10) {
				this.indeterminate = false;
				this.checkAll = true;
			} else if (data.length > 0) {
				this.indeterminate = true;
				this.checkAll = false;
			} else {
				this.indeterminate = false;
				this.checkAll = false;
			}
		},
		// 根据省份选择城市
		changePro() {
			this.cityList = this.city[this.searchData.pid];
			this.searchData.cid = '';
		},
		// 根据城市选择门店
		changeCity() {
			this.storeList = this.store[this.searchData.cid];
			this.searchData.sid = '';
		},
		// 根据门店选择用户
		changeStore() {
			if (this.searchData.sid) {
				planService.getUserList(this.searchData).then((data) => {
					this.userList = data.list;
				});
			} else {
				this.userList = [];
			}
		},
		// 点击 breadcrumb
		clickBreadcrumb(index) {
			this.selectedIndex = index;
			const doc = document.documentElement;
			let scrollTop = doc.scrollTop;
			const anchor = document.querySelectorAll('.plan-content-container')[index].offsetTop - 100;
			const scroll = function () {
				scrollTop = scrollTop + (anchor - scrollTop) / 4;
				if (Math.abs(scrollTop - anchor) < 1) {
					doc.scrollTop = anchor;
					return;
				}
				doc.scrollTop = scrollTop;
				requestAnimationFrame(scroll);
			};
			scroll();
		},
		// 生成指导方案
		generatePlan() {
			if (this.cacheProjects.length > 0) this.cacheProjects = [];
			// TODO: 记得把下面这个注释掉
			// if (this.$route.params.id !== 'all' && !this.uid) {
			//   this.$Message.warning('请选择会员')
			//   return
			// }
			if (this.selectedProjects.length === 0) {
				this.$Message.warning('请选择生成项目');
				return;
			}
			if (this.$route.params.id !== 'all') {
				this.uid = this.$route.params.id;
			}
			planService.getAutoplanData({ member_id: this.uid }).then((data) => {
				this.allProjects.forEach((item) => {
					if (this.selectedProjects.includes(item)) {
						this.cacheProjects.push(item);
					}
				});
				this.hasPlan = true;
				this.userData = data.jbxx;
				this.targetData = data.zbsj;
				this.behaviorData = data.jkxwmbzl;
				this.loseData = data.nhcf;
				this.dxk = data.dxk;
				this.trainData = data.xlk;
				this.recoveryData = data.kfk;
				this.recoveryData2 = data.kfkb;
				this.nutritionData = data.yycf;
				this.filterData = data.zbsj;
				this.actionPlan = data.zxfahz;
				this.actionWeekData = data.zjh_mb;
				if (data.sick.value) {
					this.questionOne = { other: data.sick.other, value: JSON.parse(data.sick.value) };
					this.questionTwo = { jtnl: data.jtnl_xq, glxw: data.glxw_xq };
				}
				this.healthManageData = data.zxjh;
				this.fmdz = data.fmdz;
				this.fddz = data.fddz;
			});
		},
		// 生成报告
		generateReport() {
			if (!this.hasPlan) {
				this.$Message.warning('请先生成指导方案');
				return;
			}
			const printTarget = document.querySelectorAll('.plan-content-wrapper');
			if (printTarget.length > 0) {
				const needRemove = document.querySelectorAll('.need-remove');
				needRemove.forEach((ele) => {
					ele.style.display = 'none';
				});
				const self = this;
				const pdf = new JsPDF({ compress: true, unit: 'pt' });
				for (let i = 0; i < printTarget.length; i++) {
					html2canvas(printTarget[i]).then(function (canvas) {
						const contentWidth = canvas.width;
						const contentHeight = canvas.height;

						let imgWidth = 555.28;
						let pageMargin = 20;

						const pageData = canvas.toDataURL('image/png', 1.0);

						if (i !== 0) {
							pdf.addPage();
						}

						if (printTarget[i].classList.contains('cover-no-margin')) {
							pageMargin = 0;
							imgWidth = 595;
						}
						const imgHeight = (imgWidth / contentWidth) * contentHeight;
						pdf.addImage(pageData, 'png', pageMargin, 0, imgWidth, imgHeight);

						if (i === printTarget.length - 1) {
							const date = new Date();
							const year = date.getFullYear();
							let month = date.getMonth() + 1;
							let day = date.getDate();
							if (month < 10) month = '0' + month;
							if (day < 10) day = '0' + day;
							const time = `${year}` + month + day;
							pdf.save(`${self.userData.name}管理方案 ${time}.pdf`);
						}
					});
				}
			} else {
				this.$Message.warning('无法生成报告，请检查方案是否存在');
			}
		},
	},
};
</script>

<style lang="less" scoped>
.sub-label {
  vertical-align: middle;
  float: left;
}

.sub-content {
  margin-left: 80px;
}

.btn-add {
  cursor: pointer;
  margin-left: 10px;
}

.btn-add:hover {
  color: #fdad00;
}

.content-box {
  background-color: #fff;
  padding-bottom: 20px;
}

.breadcrumbs-bar {
  display: flex;
  border-bottom: #eee solid 1px;
  position: sticky;
  top: 23px;
  background-color: #fff;
  z-index: 10;
  .breadcrumb {
    flex: 1;
    text-align: center;
    padding: 10px 0;
    font-size: 16px;
    cursor: pointer;
    &:hover {
      color: #fdad00;
    }
    &.active {
      color: #fdad00;
    }
  }
}

.content-title {
  height: 39px;
  line-height: 39px;
  padding: 0 10px;
  background-color: #fbfbfb;
}

.graph-content {
  padding: 0 30px;
}

.mb50 {
  margin-bottom: 50px;
}
</style>
