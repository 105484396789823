<template>
  <div class="plan-content-container">
    <div class="plan-content-wrapper cover-no-margin" :style="{backgroundImage: `url(${data})`}">
      <div class="cover-info-wrapper">
        <div class="cover-info">
          <div class="cover-info_label">姓名：</div>
          <div class="cover-info_text">{{user.name}}</div>
        </div>
        <div class="cover-info">
          <div class="cover-info_label">年龄：</div>
          <div class="cover-info_text">{{user.age}}</div>
        </div>
        <div class="cover-info">
          <div class="cover-info_label">性别：</div>
          <div class="cover-info_text">{{user['sex_show']}}</div>
        </div>
        <div class="cover-info">
          <div class="cover-info_label">门店：</div>
          <div class="cover-info_text">{{user.md}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script  type="text/ecmascript-6">
export default {
	props: {
		user: {
			type: Object,
		},
		data: {
			type: String,
		},
	},
	data() {
		return {};
	},
	methods: {},
};
</script>

<style lang="less" scoped>
.plan-content-wrapper {
  background-size: cover;
  height: 1300px;
  overflow: hidden;
  .cover-info-wrapper {
    width: 220px;
    background-color: #e25028;
    color: #fff;
    border-radius: 12px;
    padding: 10px 20px;
    font-size: 16px;
    margin: 1020px 0 0 580px;
    .cover-info {
      margin-bottom: 5px;
    }
    .cover-info_label {
      display: inline-block;
    }
    .cover-info_text {
      border-bottom: #fff solid 1px;
      display: inline-block;
      width: 125px;
      text-align: center;
    }
  }
}
</style>
