<template>
  <div class="plan-content-container">
    <div class="plan-content-wrapper">
      <div class="plan-content-header">
        <div class="plan-content-header_img-wrapper">
          <img class="plan-content-header_img" src="@/assets/img/report-bg.jpg" alt="background">
        </div>
        <div class="plan-content-header_title">健康行为执行促进周计划</div>
        <div class="plan-content-header_text">
          <span>姓名：{{user.name}}</span>
          <span>性别：{{user['sex_show']}}</span>
          <span>年龄：{{user.age}}</span>
          <span>门店：{{user.md}}</span>
        </div>
      </div>
      <div class="plan-content-body">
        <table class="class-table">
          <tr>
            <th class="class-table_time">时间</th>
            <th>周一</th>
            <th>周二</th>
            <th>周三</th>
            <th>周四</th>
            <th>周五</th>
            <th>周六</th>
          </tr>
          <template v-for="(upperTr, index) in upperData">
            <tr :key="'tr' + index + count" v-for="count in 3">
              <th class="class-table_time" rowspan="3" v-if="count === 1">{{upperTr.sd}}</th>
              <td v-for="td in 6" :key="'td' + td">{{upperTr['nr' + td][count - 1]}}</td>
            </tr>
          </template>
          <tr><th colspan="7">12:00 - 14:00</th></tr>
          <template v-for="(bottomTr, index) in bottomData">
            <tr :key="'tr1' + index + count" v-for="count in 3">
              <th class="class-table_time" rowspan="3" v-if="count === 1">{{bottomTr.sd}}</th>
              <td v-if="index === 2 && count === 3" colspan="6">智能手机班/舞蹈/唱歌</td>
              <td v-else v-for="td in 6" :key="'td1' + td">{{bottomTr['nr' + td][count - 1]}}</td>
            </tr>
          </template>
          <tr><th colspan="7" class="class-table_time">训练及复建营业时间 8:00 - 17:00，采取预约限流制</th></tr>
        </table>
        <table class="class-table">
          <tr>
            <th class="class-table_time">时间</th>
            <th>周一</th>
            <th>周二</th>
            <th>周三</th>
            <th>周四</th>
            <th>周五</th>
            <th>周六</th>
          </tr>
          <template v-for="(upperTr, index) in upperData">
            <tr :key="'tr' + index + count" v-for="count in 3">
              <th class="class-table_time" rowspan="3" v-if="count === 1">{{upperTr.sd}}</th>
              <td v-for="td in 6" :key="'td' + td">&nbsp;</td>
            </tr>
          </template>
          <tr><th colspan="7">12:00 - 14:00</th></tr>
          <template v-for="(bottomTr, index) in bottomData">
            <tr :key="'tr1' + index + count" v-for="count in 3">
              <th class="class-table_time" rowspan="3" v-if="count === 1">{{bottomTr.sd}}</th>
              <td v-for="td in 6" :key="'td1' + td">&nbsp;</td>
            </tr>
          </template>
          <tr><th colspan="7" class="class-table_time">训练及复建营业时间 8:00 - 17:00，采取预约限流制</th></tr>
        </table>
      </div>
      <div class="content-footer">
        <p>郑重说明：本⻚内容不能替代临床处⽅，仅⽤于健康行为促进</p>
      </div>
    </div>
  </div>
</template>

<script  type="text/ecmascript-6">
export default {
	props: {
		user: {
			type: Object,
		},
		data: {
			type: Array,
		},
	},
	data() {
		return {
			upperData: [],
			bottomData: [],
		};
	},
	created() {
		this.upperData = Array.from(this.data).splice(0, 4);
		this.bottomData = this.data;
	},
	methods: {
		replaceBreak(val) {
			return val.replace(/\r/g, '<br>');
		},
	},
};
</script>

<style lang="less" scoped>
.class-table {
  width: 90%;
  margin: 20px auto;
  table-layout: fixed;
  text-align: center;
  tr:nth-child(even) {
    background-color: #eee;
  }
  th {
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    background-color: #306a81;
    border: 1px #fff solid;
  }
  &_time {
    background-color: #e55530!important;
  }
}
</style>
