export default {
	1: {
		core: '先天基因异常或后天胰岛负荷增大导致的血糖吸收与转换效率的代谢异常。',
		factor1: '血液中摄入与消耗是否平衡，决定了血液中血糖的含量处于正常范围。',
		factor2: '血液中摄入与消耗是否平衡，决定了血液中血糖的含量处于正常范围。',
		factor3: '血液中摄入与消耗是否平衡，决定了血液中血糖的含量处于正常范围。',
	},
	2: {
		core: '当心肌细胞、血管平滑肌细胞受损，血压、血管质量下降，心脏功能降低时，循环系统便会出现问题。',
		factor1:
			'以脂代谢为代表的能量摄入和消耗的比例，以钠钾钙为首的电解质交换为代表的代谢平衡，决定了循环系统正常运转的整个内环境。',
		factor2: '心脏功能、血管弹性、血液质量、决定了循环系统的血流运输空间储备能力。',
		factor3:
			'细胞是组成人体的基本单位，结构决定功能，细胞的数量，质量，敏感性如何从整体决定了组织器官的功能，心肌细胞，血管平滑肌细胞的微观结构是构成循环系统的基本要素。',
	},
	3: {
		core: '运动系统疾病核心在于骨骼代谢异常，骨萎程度加快，成骨细胞与破骨细胞比例失衡，引起增生性疾病。',
		factor1:
			'骨膜是骨骼代谢的关键结构，营养素摄入失衡加上细胞膜损伤，使得大量的营养物质无法进入骨细胞，低消耗量也让骨细胞无法正常生长。',
		factor2:
			'骨骼生长有自己专属的空间，修复期会收到韧带和肌纤维的结构的影响，如韧带丧失弹性，肌纤维收缩舒张能力下降，会严重影响骨骼的正常生长。',
		factor3:
			'因为营养物质交换受阻，钙质无法进入，使成骨细胞与破骨细胞比例失衡，引起一系列增生性疾病与骨细胞损伤疾病。',
	},
	4: {
		core: '机体消耗量过少或摄入量过多条件下会导致多余脂质的赘余引起体内脂代谢的紊乱。',
		factor1:
			'机体内储脂器官中脂肪分布合理，脂肪代谢器官功能完善，无代偿性负荷。作为血脂运输的通路，血管结构的完整、心肺功能的正常，是保障血脂代谢均衡的基础。',
		factor2:
			'食物中脂肪摄入与消耗的平衡与否，决定了血液中血脂的含量。机体能量的摄取与消耗平衡是控制血脂稳定的关键。',
		factor3:
			'与血脂合成及分解相关肝脏细胞、组织细胞的数量、结构的完整。血脂的运输通道血管壁细胞功能完善。',
	},
	5: {
		core: '肺部活动空间狭小，肺泡气体交换出现阻塞，导致全身缺血缺氧。',
		factor1: '血氧交换能力变差、大脑皮层神经递质及活性物质被抑制，严重影响脑部的代谢活动。',
		factor2:
			'气道、肺泡、肺血管、胸廓呼吸肌调节是气体交换流动的活动支持空间，气道变窄、肺实质的功能无法正常进行，呼吸受阻，直接危及生命。',
		factor3:
			'氧气与二氧化碳气体交换的顺利完成依赖于气管细胞、呼吸膜的肺泡细胞、血管平滑肌细胞功能正常发挥，这些细胞的数量减少、质量退化、敏感性下降，从根源上促进了呼吸系统慢性疾病的发生与蔓延。',
	},
};
