<template>
  <div class="plan-content-container">
    <div class="plan-content-wrapper">
      <div class="plan-content-header">
        <div class="plan-content-header_img-wrapper">
          <img class="plan-content-header_img" src="@/assets/img/report-bg.jpg" alt="background">
        </div>
        <div class="plan-content-header_title">健康行为执行方案汇总</div>
        <div class="plan-content-header_text">
          <span>姓名：{{user.name}}</span>
          <span>性别：{{user['sex_show']}}</span>
          <span>年龄：{{user.age}}</span>
          <span>门店：{{user.md}}</span>
        </div>
      </div>
      <div class="plan-content-body">
        <p class="action-plan-tip">确认：6个⽉未进⾏⼿术或1年内未进⾏过重⼤⼿术、⾮医嘱建议不适合运动的⼈群</p>
        <table class="action-plan-table">
          <template v-for="(item, i) in result">
            <tr class="action-plan-table_tr" :key="i" @click="handleClick(i)">
              <th rowspan="2" class="action-plan-table_th">
                <img :src="mapImage(item.en)" alt="">
              </th>
              <td class="action-plan-table_td" v-for="(val, i) in item.children" :key="i">
                <span class="td-dot"></span><span>{{val.name}}</span>
                <div style="margin-top: 5px;">{{val.subName}}</div>
              </td>
            </tr>
            <tr :key="'tr' + i" @click="handleClick(i)"><td colspan="3">{{item.tip}}</td></tr>
          </template>
          <tfoot>
            <tr><td colspan="4" class="action-plan-table_tfoot-td">注意事项：参与项⽬过程中，如有任何不适，请及时通知教练。</td></tr>
          </tfoot>
        </table>
        <div class="need-remove" style="text-align:center;" v-if="result.length != 5">
          <Button type="primary" @click="handleAdd">添加方案</Button>
          <span style="font-size:14px;">（单击项目以编辑）</span>
        </div>
      </div>
      <Modal
        v-model="showModal"
        title="添加项目"
        class-name="vertical-center-modal"
        @on-ok="onOk"
        @on-cancel="onCancel">
        <Form :label-width="70" label-position="right">
          <Form-item label="项目：">
            <Select v-model="item.project" style="width:100px" @on-change="projectChange">
              <Option :value="key" v-for="(value, key) in selectItems" :key="key">{{value.name}}</Option>
            </Select>
          </Form-item>
          <Form-item label="动作：">
            <Select v-model="temp1" style="width: 100%" @on-change="categoryChange">
              <Option :value="value.id" v-for="value in childSelectItems" :key="value.id">{{value.name}}</Option>
            </Select>
          </Form-item>
          <Form-item label="指标：">
            <Select v-model="temp2" style="width: 100%" @on-change="lastChange">
              <Option :value="item.name" v-for="item in lastSelectItems" :key="item.id">{{item.name}}</Option>
            </Select>
          </Form-item>
          <Form-item label="已选择：">
            <span>{{item.children.length ? item.children.map(item => item.name).join(',') : '无'}}</span>
          </Form-item>
          <Form-item label="说明：">
            <Input v-model="item.tip" type="textarea" :rows="3" placeholder="请补充说明"></Input>
          </Form-item>
        </Form>
      </Modal>
      <div style="display: flex;justify-content: space-around;font-size: 16px;margin: 20px 0;font-weight: 700;">
        <span>会员签名：</span>
        <span>教练签名：</span>
        <span style="padding-right:80px;">开具时间：</span>
      </div>
      <div class="content-footer">
        <p>来源：《中国营养师培训教材》、《疾病营养学》、《ACSM运动处⽅师》、《常⻅慢病运动处⽅》、《ACE功能训练学》</p>
        <p>郑重说明：本⻚内容不能替代临床处⽅，仅⽤于健康行为促进</p>
      </div>
    </div>
  </div>
</template>

<script  type="text/ecmascript-6">
export default {
	props: {
		user: {
			type: Object,
		},
		data: {
			type: Array,
		},
	},
	data() {
		return {
			showModal: false,
			item: {
				project: '',
				children: [],
				tip: '',
			},
			result: [],
			selectItems: {},
			childSelectItems: [],
			lastSelectItems: [],
			selectIndex: '',
			cache: {},
			selectedCategory: {},
			temp1: '',
			temp2: '',
		};
	},
	created() {
		for (let i in this.data) {
			let item = this.data[i];
			this.selectItems[item['id']] = item;
		}
	},
	methods: {
		handleAdd() {
			this.showModal = true;
		},
		onOk() {
			if (this.selectIndex !== '') {
				this.result[this.selectIndex] = { ...this.item, ...this.selectItems[this.item.project] };
			} else {
				this.result.push({ ...this.item, ...this.selectItems[this.item.project] });
			}
			this.selectIndex = '';
			this.item = {
				project: '',
				children: [],
				tip: '',
			};
			this.childSelectItems = [];
			this.lastSelectItems = [];
			this.temp1 = '';
			this.temp2 = '';
		},
		onCancel() {
			this.showModal = false;
			this.selectIndex = '';
			this.item = {
				project: '',
				children: [],
				tip: '',
			};
			this.childSelectItems = [];
			this.lastSelectItems = [];
			this.temp1 = '';
			this.temp2 = '';
		},
		projectChange(val) {
			if (val) {
				this.childSelectItems = this.selectItems[val]['xl'];
			}
		},
		categoryChange(val) {
			if (val) {
				const selected = this.childSelectItems.find((item) => item.id === val);
				this.selectedCategory = selected;
				this.lastSelectItems = selected['zl'];
				this.temp2 = '';
			}
		},
		lastChange(val) {
			if (val) {
				const res = {
					id: this.selectedCategory['id'],
					name: this.selectedCategory.name,
					subName: val,
				};
				if (this.item.children.some((item) => item.id === res.id)) {
					this.item.children.forEach((item) => {
						if (item.id === res.id) {
							item = res;
						}
					});
				} else {
					this.item.children.push(res);
				}
			}
		},
		handleClick(i) {
			this.selectIndex = i;
			const copy = { ...this.result[i] };
			this.item = copy;
			this.item.children = [];
			this.childSelectItems = this.selectItems[copy['id']]['xl'];
			this.showModal = true;
		},
		mapImage(val) {
			switch (val) {
				case 'Energy':
					return '@/assets/img/action-energy.png';
				case 'Train':
					return '@/assets/img/action-train.png';
				case 'Nutrition':
					return '@/assets/img/action-nutrition.png';
				case 'Rehabilitation':
					return '@/assets/img/action-rehabilitation.png';
				default:
					return '@/assets/img/action-round-table.png';
			}
		},
		replaceBreak(val) {
			return val.replace(/\r/g, '<br>');
		},
	},
};
</script>

<style lang="less" scoped>
.action-plan-tip {
  margin: 20px 0;
  text-indent: 2em;
  font-size: 14px;
  font-weight: 700;
}
.action-plan-table {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  &_tr {
    border-top: solid #000 2px;
  }
  &_th {
    border-right: solid #000 2px;
    width: 15%;
    padding: 15px 0;
    img {
      height: 130px;
    }
  }
  &_td {
    border-bottom: solid #000 2px;
    height: 58px;
    padding: 10px;
    .td-dot {
      background-color: #000;
      display: inline-block;
      height: 6px;
      width: 6px;
      border-radius: 3px;
      margin-right: 10px;
    }
    span {
      vertical-align: middle;
    }
  }
  &_tfoot-td {
    padding: 5px 10px;
    background-color: #5b93af;
    font-size: 14px;
    color: #fff;
    text-align: left;
  }
}
</style>
