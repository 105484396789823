<template>
  <div class="plan-content-container">
    <div class="plan-content-wrapper cover-no-margin" :style="{backgroundImage: `url(${data})`}">
    </div>
  </div>
</template>

<script  type="text/ecmascript-6">
export default {
	props: {
		user: {
			type: Object,
		},
		data: {
			type: String,
		},
	},
};
</script>

<style lang="less" scoped>
.plan-content-wrapper {
  background-size: cover;
  height: 1300px;
  overflow: hidden;
}
</style>
