<template>
  <div class="plan-content-container">
    <div class="plan-content-wrapper">
      <div class="plan-content-header">
        <div class="plan-content-header_img-wrapper">
          <img class="plan-content-header_img" src="@/assets/img/report-bg.jpg" alt="background">
        </div>
        <div class="plan-content-header_title">健康风险评估问卷(一)</div>
        <div class="plan-content-header_text">
          <span>姓名：{{user.name}}</span>
          <span>性别：{{user['sex_show']}}</span>
          <span>年龄：{{user.age}}</span>
          <span>门店：{{user.md}}</span>
        </div>
      </div>
      <div class="plan-content-body">
        <div class="disease-card" v-for="firstArray in data.value" :key="firstArray.type">
            <div class="title">{{firstArray.name}}</div>
            <div class="checkbox-panel">
                <template v-if="firstArray.type !== 5">
                <template v-for="(item, index) in firstArray.list">
                  <div :class="['checkbox-content', item.value ? 'checked' : '']" :key="'content'+index">{{item.name}}</div>
                  <br v-if="((index + 1) % 4 === 0)" :key="item.id">
                </template>
                </template>
                <div class="sub-disease-wrapper" v-else>
                <template v-for="secondArray in firstArray.list">
                    <div class="sub-disease" :key="secondArray.name">
                    <span>{{secondArray.name}}</span>
                    <div v-for="secondItem in secondArray.list" :key="secondItem.id" :class="['checkbox-content', secondItem.value ? 'checked' : '']">{{secondItem.name}}</div>
                    </div>
                </template>
                </div>
            </div>
        </div>
        <div class="remark-panel">
            <div class="title">6.备注</div>
            <div>{{data.other}}</div>
        </div>
      </div>
      <div class="content-footer">
        <p>来源：《公共卫生学》、《健康大数据统计学》、《20年慢病人群数据模型库》等</p>
        <p>郑重说明：本⻚内容不能替代临床处⽅，仅⽤于健康行为促进</p>
      </div>
    </div>
    <div class="plan-content-wrapper">
      <div class="plan-content-header">
        <div class="plan-content-header_img-wrapper">
          <img class="plan-content-header_img" src="@/assets/img/report-bg.jpg" alt="background">
        </div>
        <div class="plan-content-header_title">ABO行为分型问卷(二)</div>
        <div class="plan-content-header_text">
          <span>姓名：{{user.name}}</span>
          <span>性别：{{user['sex_show']}}</span>
          <span>年龄：{{user.age}}</span>
          <span>门店：{{user.md}}</span>
        </div>
      </div>
      <div class="plan-content-body">
      <div class="behavior-card">
        <div class="title">A.机体能力评估问卷</div>
        <div class="behavior-content">
          <Row class="behavior-content-row">
            <Col span="14">1.您近一个月静息血压是否超过140/90mmHg？</Col>
            <Col class="behavior-content-col">
              <div :class="['checkbox-content', bodyResult[0] === '1' ? 'checked' : '']">是</div>
              <div :class="['checkbox-content', bodyResult[0] === '0' ? 'checked' : '']">否</div>
            </Col>
          </Row>
          <Row class="behavior-content-row">
            <Col span="14">2.您近三个月是否一直在服用降血压或缓解心脏疾患的药物？</Col>
            <Col class="behavior-content-col">
              <div :class="['checkbox-content', bodyResult[1] === '1' ? 'checked' : '']">是</div>
              <div :class="['checkbox-content', bodyResult[1] === '0' ? 'checked' : '']">否</div>
            </Col>
          </Row>
          <Row class="behavior-content-row">
            <Col span="14">3.您的静息心率是否>75次/分？</Col>
            <Col class="behavior-content-col">
              <div :class="['checkbox-content', bodyResult[2] === '1' ? 'checked' : '']">是</div>
              <div :class="['checkbox-content', bodyResult[2] === '0' ? 'checked' : '']">否</div>
            </Col>
          </Row>
          <Row class="behavior-content-row">
            <Col span="14">4.您是否有一过性眼⿊、头昏或明确诊断有脑中风？（下蹲起⽴）</Col>
            <Col class="behavior-content-col">
              <div :class="['checkbox-content', bodyResult[3] === '1' ? 'checked' : '']">是</div>
              <div :class="['checkbox-content', bodyResult[3] === '0' ? 'checked' : '']">否</div>
            </Col>
          </Row>
          <Row class="behavior-content-row">
            <Col span="14">5.您是否有过胸痛、左肩部疼痛或明确诊断有心肌缺⾎、冠心病？</Col>
            <Col class="behavior-content-col">
              <div :class="['checkbox-content', bodyResult[4] === '1' ? 'checked' : '']">是</div>
              <div :class="['checkbox-content', bodyResult[4] === '0' ? 'checked' : '']">否</div>
            </Col>
          </Row>
          <Row class="behavior-content-row">
            <Col span="14">6.您常速步行1-2公里，上3楼是否疲劳、胸痛、心悸、心绞痛？</Col>
            <Col class="behavior-content-col">
              <div :class="['checkbox-content', bodyResult[5] === '1' ? 'checked' : '']">是</div>
              <div :class="['checkbox-content', bodyResult[5] === '0' ? 'checked' : '']">否</div>
            </Col>
          </Row>
          <Row class="behavior-content-row">
            <Col span="14">7.您常速步行1-2公里，上3楼是否胸闷、气促、喘气、呼吸困难？</Col>
            <Col class="behavior-content-col">
              <div :class="['checkbox-content', bodyResult[6] === '1' ? 'checked' : '']">是</div>
              <div :class="['checkbox-content', bodyResult[6] === '0' ? 'checked' : '']">否</div>
            </Col>
          </Row>
          <Row class="behavior-content-row">
            <Col span="14">8.您运动时是否感觉呼吸不顺畅或有异响（肺活量的达标率&lt;70%？）</Col>
            <Col class="behavior-content-col">
              <div :class="['checkbox-content', bodyResult[7] === '1' ? 'checked' : '']">是</div>
              <div :class="['checkbox-content', bodyResult[7] === '0' ? 'checked' : '']">否</div>
            </Col>
          </Row>
          <Row class="behavior-content-row">
            <Col span="14">9.您是否近⼀年发⽣过骨痛或骨折？</Col>
            <Col class="behavior-content-col">
              <div :class="['checkbox-content', bodyResult[8] === '1' ? 'checked' : '']">是</div>
              <div :class="['checkbox-content', bodyResult[8] === '0' ? 'checked' : '']">否</div>
            </Col>
          </Row>
          <Row class="behavior-content-row">
            <Col span="14">10.您近三个⽉是否有关节轻度酸痛不适？</Col>
            <Col class="behavior-content-col">
              <div :class="['checkbox-content', bodyResult[9] === '1' ? 'checked' : '']">是</div>
              <div :class="['checkbox-content', bodyResult[9] === '0' ? 'checked' : '']">否</div>
            </Col>
          </Row>
      </div>
      <div class="behavior-score">
        <span>问卷评分：</span>
        <span>{{bodyScore}}</span>
      </div>
    </div>
    <div class="behavior-card">
      <div class="title">B.观念行为评估问卷</div>
      <div class="behavior-content">
        <Row class="behavior-content-row">
          <Col span="12">1.您是否有主动运动？</Col>
          <Col class="behavior-content-col">
            <div :class="['checkbox-content', optionResult[0] === '1' ? 'checked' : '']">是</div>
            <div :class="['checkbox-content', optionResult[0] === '0' ? 'checked' : '']">否</div>
          </Col>
        </Row>
        <Row class="behavior-content-row">
          <Col span="12">2.您是否运动前有热⾝的准备活动？</Col>
          <Col class="behavior-content-col">
            <div :class="['checkbox-content', optionResult[1] === '1' ? 'checked' : '']">是</div>
            <div :class="['checkbox-content', optionResult[1] === '0' ? 'checked' : '']">否</div>
          </Col>
        </Row>
        <Row class="behavior-content-row">
          <Col span="12">3.您是否监测过运动中和运动后的心率？</Col>
          <Col class="behavior-content-col">
            <div :class="['checkbox-content', optionResult[2] === '1' ? 'checked' : '']">是</div>
            <div :class="['checkbox-content', optionResult[2] === '0' ? 'checked' : '']">否</div>
          </Col>
        </Row>
        <Row class="behavior-content-row">
          <Col span="12">4.您是否在运动后进行全⾝放松活动？</Col>
          <Col class="behavior-content-col">
            <div :class="['checkbox-content', optionResult[3] === '1' ? 'checked' : '']">是</div>
            <div :class="['checkbox-content', optionResult[3] === '0' ? 'checked' : '']">否</div>
          </Col>
        </Row>
        <Row class="behavior-content-row">
          <Col span="12">5.您进行大步⾛、单车等中等强度运动的感受：</Col>
          <Col class="behavior-content-col">
            <div :class="['checkbox-content', optionResult[4] === '1' ? 'checked' : '']">吃力</div>
            <div :class="['checkbox-content', optionResult[4] === '2' ? 'checked' : '']">适宜</div>
            <div :class="['checkbox-content', optionResult[4] === '3' ? 'checked' : '']">轻松</div>
            <div :class="['checkbox-content', optionResult[4] === '0' ? 'checked' : '']">无运动</div>
          </Col>
        </Row>
        <Row class="behavior-content-row">
          <Col span="12">6.您进行哑铃等运动器械或⾝体针对性训练的感受：</Col>
          <Col class="behavior-content-col">
            <div :class="['checkbox-content', optionResult[5] === '1' ? 'checked' : '']">吃力</div>
            <div :class="['checkbox-content', optionResult[5] === '2' ? 'checked' : '']">适宜</div>
            <div :class="['checkbox-content', optionResult[5] === '3' ? 'checked' : '']">轻松</div>
            <div :class="['checkbox-content', optionResult[5] === '0' ? 'checked' : '']">无运动</div>
          </Col>
        </Row>
      </div>
      <div class="behavior-score">
        <span>问卷评分：</span>
        <span>{{optionScore}}</span>
      </div>
    </div>
      </div>
      <div class="content-footer">
        <p>来源：《美国运动医学会PAR-Q问卷》、《运动生理学》、《群体行为分析学》等</p>
        <p>郑重说明：本页内容不能替代临床处方，仅用于健康行为促进</p>
      </div>
    </div>
  </div>
</template>

<script  type="text/ecmascript-6">
export default {
	props: {
		user: {
			type: Object,
		},
		data: {
			type: Object,
		},
		question: {
			type: Object,
		},
	},
	computed: {
		bodyScore() {
			if (this.bodyResult.length === 0) {
				return 0;
			} else {
				return this.bodyResult.reduce((before, last) => Number(before) + Number(last));
			}
		},
		optionScore() {
			if (this.optionResult.length === 0) {
				return 0;
			} else {
				return this.optionResult.reduce((before, last) => Number(before) + Number(last));
			}
		},
	},
	data() {
		return {
			bodyResult: [],
			optionResult: [],
		};
	},
	created() {
		this.bodyResult = this.question.jtnl.split(',');
		this.optionResult = this.question.glxw.split(',');
	},
	methods: {},
};
</script>

<style lang="less" scoped>
.disease-card {
  margin: 20px auto;
  width: 80%;
  display: flex;
  flex-direction: column;
  .title {
    color: blue;
    font-weight: 700;
    font-size: 16px;
  }
  .divider {
    width: 100%;
    height: 2px;
    background-color: #eee;
    margin-top: 20px;
  }
  .checkbox-panel {
    margin: auto;
  }
  .checkbox-content {
    font-size: 16px;
    width: 150px;
    margin-top: 5px;
    display: inline-block;
    padding-left: 30px;
    background: url(@/assets/img/checkbox.png) no-repeat left / 16px;
    &.checked {
      background-image: url(@/assets/img/checkedbox.png)
    }
  }
  .sub-disease-wrapper {
    display: flex;
    margin: auto;
    .sub-disease {
    span {
      color: blue;
      font-size: 16px;
      margin: 10px 0 0 20px;
      display: inline-block;
    }
    width: 160px;
    }
  }
}
.behavior-card {
  margin: 20px auto 50px;
  font-size: 16px;
  .behavior-content-row {
      margin: 10px 0;
  }
  .title {
    color: blue;
    font-weight: 700;
  }
  .behavior-content-col {
    display: flex;
    justify-content: space-evenly;
    .checkbox-content {
      font-size: 16px;
      padding-left: 30px;
      background: url(@/assets/img/checkbox.png) no-repeat left / 16px;
      &.checked {
        background-image: url(@/assets/img/checkedbox.png)
      }
    }
  }
  .behavior-score {
    font-size: 18px;
    font-weight: 700;
    color: blue;
    margin: 20px 0;
    span:nth-child(2) {
      padding: 0 15px;
      font-size: 32px;
      border-bottom: solid 2px blue;
    }
  }
  &:nth-child(2) {
    .behavior-content-col {
      display: block;
      .checkbox-content {
        display: inline-block;
        margin-right: 20px;
        font-size: 16px;
        padding-left: 30px;
        background: url(@/assets/img/checkbox.png) no-repeat left / 16px;
        &.checked {
          background-image: url(@/assets/img/checkedbox.png)
        }
      }
    }
  }
}
.remark-panel {
  margin: 0 auto 10px;
  width: 80%;
  .title {
    color: blue;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
  }
}
</style>
