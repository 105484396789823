<template>
  <div class="plan-content-container">
    <div class="plan-content-wrapper">
      <div class="plan-content-header">
        <div class="plan-content-header_img-wrapper">
          <img class="plan-content-header_img" src="@/assets/img/report-bg.jpg" alt="background">
        </div>
        <div class="plan-content-header_title">健康管理执行计划</div>
        <div class="plan-content-header_text">
          <span>姓名：{{user.name}}</span>
          <span>性别：{{user['sex_show']}}</span>
          <span>年龄：{{user.age}}</span>
          <span>门店：{{user.md}}</span>
        </div>
      </div>
      <div class="plan-content-body">
        <div class="data-panel" v-for="(data, i) in  data" :key="i">
          <div class="data-panel_title">{{data.id}}</div>
          <div class="data-panel_content" v-html="replaceBreak(data.name)"></div>
        </div>
      </div>
      <div class="content-footer">
        <p>郑重说明：本⻚内容不能替代临床处⽅，仅⽤于健康行为促进</p>
      </div>
    </div>
  </div>
</template>

<script  type="text/ecmascript-6">
export default {
	props: {
		user: {
			type: Object,
		},
		data: {
			type: Array,
		},
	},
	data() {
		return {};
	},
	methods: {
		replaceBreak(val) {
			return val.replace(/\r/g, '<br>').replace(/ /g, '&ensp;');
		},
	},
};
</script>

<style lang="less" scoped>
.data-panel {
  margin: 20px 70px;
  &_title {
    color: #4472c4;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 5px;
  }
  &_content {
    font-size: 18px;
    line-height: 1.6;
  }
}
</style>
