<template>
  <div class="plan-content-container">
    <div class="plan-content-wrapper">
      <div class="plan-content-header">
        <div class="plan-content-header_img-wrapper">
          <img class="plan-content-header_img" src="@/assets/img/report-bg.jpg" alt="background">
        </div>
        <div class="plan-content-header_title">健康生理目标指南</div>
        <div class="plan-content-header_text">
          <span>姓名：{{user.name}}</span>
          <span>性别：{{user['sex_show']}}</span>
          <span>年龄：{{user.age}}</span>
          <span>门店：{{user.md}}</span>
        </div>
      </div>
      <div class="plan-content-body">
        <div class="target-data_title">生理干预目标</div>
        <div class="target-data_text">经过盛世康和标准化的健康管理后，使您的各项生理指标趋于标准范围，预防慢性疾病与多种并发症的发生。</div>
        <table class="target-data_table">
          <tr>
            <th>项目</th>
            <th>下限</th>
            <th>上限</th>
            <th>标准值</th>
            <th>现值</th>
            <th>差值</th>
            <th>目标</th>
          </tr>
          <tr v-for="(val, index) in data['jcsz_list']" :key="index">
            <td>{{val.name}}</td>
            <td>{{val.xx}}</td>
            <td>{{val.sx}}</td>
            <td>{{val.bzz}}</td>
            <td>{{val.xz}}</td>
            <td>{{val.cz}}</td>
            <td>{{val.mb}}</td>
          </tr>
        </table>
      </div>
      <div class="content-footer">
        <p>来源：《公共卫⽣学》、《健康大数据统计学》、《20年慢病人群数据模型库》、《中老年人体适能测试⼿册》等</p>
        <p>郑重说明：本⻚内容不能替代临床处⽅，仅⽤于健康行为促进</p>
      </div>
    </div>
  </div>
</template>

<script  type="text/ecmascript-6">
export default {
	props: {
		user: {
			type: Object,
		},
		data: {
			type: Object,
		},
		behavior: {
			type: Object,
		},
	},
	data() {
		return {};
	},
	methods: {
		replaceBreak(val) {
			return val.replace(/\r/g, '<br>');
		},
		mapClass(val, type = 'column') {
			if (val === -1) {
				return `target-${type}_pic target-column_pic--low`;
			} else if (val === 0) {
				return `target-${type}_pic target-column_pic--normal`;
			} else if (val === 1) {
				return `target-${type}_pic target-column_pic--high`;
			} else {
				return '';
			}
		},
	},
};
</script>

<style lang="less" scoped>
.plan-content-wrapper {
  .target-data_title {
    background-color: #4472c4;
    border-radius: 4px;
    color: #fff;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    width: 160px;
    margin: 25px 0;
  }
  .target-data_text {
    text-indent: 2em;
    line-height: 1.5;
    font-size: 16px;
    font-weight: 700;
  }
  .target-data_chartlet {
    display: flex;
    justify-content: space-around;
    margin: 15px 0;
    img {
      width: 88px;
    }
    p {
      font-size: 16px;
      text-align: center;
    }
  }
  .target-data_table {
    width: 100%;
    margin: 25px 0;
    tr {
      &:nth-child(even) {
        background-color: #d2deef;
      }
      &:nth-child(odd) {
        background-color: #eaeff7;
      }
    }
    th {
      background-color: #4472c4;
      color: #fff;
      border: #fff solid 2px;
      height: 45px;
    }
    td {
      border: #fff solid 2px;
      text-align: center;
      height: 35px;
      &:nth-child(1) {
        font-weight: 700;
      }
    }
  }
}
</style>
