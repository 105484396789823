<template>
  <div class="plan-content-container">
    <div class="plan-content-wrapper">
      <div class="plan-content-header">
        <div class="plan-content-header_img-wrapper">
          <img class="plan-content-header_img" src="@/assets/img/report-bg.jpg" alt="background">
        </div>
        <div class="plan-content-header_title">五维健康风险评估</div>
        <div class="plan-content-header_text">
          <span>姓名：{{user.name}}</span>
          <span>性别：{{user['sex_show']}}</span>
          <span>年龄：{{user.age}}</span>
          <span>门店：{{user.md}}</span>
        </div>
      </div>
      <div class="plan-content-body">
        <Row class="graph-panel">
          <Col span="12"><div class="filter-chart" ref="left"></div></Col>
          <Col span="12">
            <div class="filter-right">
              <div class="filter-right-title">风险程度评估:柱状图</div>
              <div class="filter-body">
                <div class="filter-body-left">
                  <ul class="legent-list">
                    <li class="legent-list-item" v-for="(res, i) in data.qz_sj_title" :key="i*10">{{res}}</li>
                  </ul>
                </div>
                <div class="filter-body-right">
                  <div class="filter-pic-body">
                    <ul class="data-list">
                      <li class="data-list-item" :style="setChartStyle(res)" v-for="(res, i) in data.qz_sj_value" :key="i"></li>
                    </ul>
                  </div>
                  <ul class="filter-pic-footer">
                    <li>0</li><li>25</li><li>50</li><li>75</li><li>100</li>
                  </ul>
                </div>
              </div>
              <div class="filter-body-footer"></div>
            </div>
          </Col>
        </Row>
        <div class="filter-result">
          <div class="filter-result_left">
            <span class="filter-result_field">评估结果：</span><span>{{data.glfx}}</span>
          </div>
          <div class="filter-result_right">
            <span class="filter-result_field">综合评分：</span><span>{{data.zhpf}}分</span>
          </div>
        </div>
        <div class="content-panel">
          <div class="content-panel-section">
            <div class="content-panel-section_title">完整性营养</div>
            <img src="@/assets/img/report-01.png" class="content-panel-section_image">
            <ul class="content-panel-section_list">
              <li>+ 三⼤产能营养素</li>
              <li>+ 膳⻝营养均衡</li>
              <li>+ 主⻝搭配与均衡</li>
              <li>+ 矿物质微量元素</li>
            </ul>
          </div>
          <div class="content-panel-section">
            <div class="content-panel-section_title">平衡性能耗</div>
            <img src="@/assets/img/report-02.png" class="content-panel-section_image">
            <ul class="content-panel-section_list">
              <li>- 能耗总量设定</li>
              <li>- 能耗⽅式优选</li>
              <li>- 能耗时间控制</li>
              <li>- 能耗强度调整</li>
            </ul>
          </div>
          <div class="content-panel-section">
            <div class="content-panel-section_title">适应性训练</div>
            <img src="@/assets/img/report-03.png" class="content-panel-section_image">
            <ul class="content-panel-section_list">
              <li>× 哈斯博⼼肺团训</li>
              <li>× 哈斯博抗阻团训</li>
              <li>× 哈斯博⼒量团训</li>
              <li>× 哈斯博柔韧团训</li>
            </ul>
          </div>
          <div class="content-panel-section">
            <div class="content-panel-section_title">储备性复健</div>
            <img src="@/assets/img/report-04.png" class="content-panel-section_image">
            <ul class="content-panel-section_list">
              <li>÷ 器官修复靶向</li>
              <li>÷ 神经调节强度</li>
              <li>÷ 超量恢复速率</li>
              <li>÷ 细胞营养补充</li>
            </ul>
          </div>
        </div>
        <div class="content-footer">
          <p>来源：《公共卫⽣学》、《健康大数据统计学》、《20年慢病人群数据模型库》等</p>
          <p>郑重说明：本⻚内容不能替代临床处⽅，仅⽤于健康行为促进</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script  type="text/ecmascript-6">
import echarts from 'echarts';
import filterData from './filter';

export default {
	props: {
		user: {
			type: Object,
		},
		data: {
			type: Object,
		},
	},
	mounted() {
		const _this = this;
		function commonSingleOpt() {
			const option = {
				title: {
					text: '风险程度评估:雷达图',
					textStyle: {
						fontSize: 16,
						fontWeight: 700,
					},
					left: 145,
				},
				radar: [
					{
						indicator: [
							{ text: '心功能', max: 100, color: '#000' },
							{ text: '肺功能', max: 100, color: '#000' },
							{ text: '糖代谢', max: 100, color: '#000' },
							{ text: '脂代谢', max: 100, color: '#000' },
							{ text: '骨强度', max: 100, color: '#000' },
						],
						name: {
							fontSize: 14,
						},
						center: ['50%', '60%'],
						radius: 100,
					},
				],
				series: [
					{
						type: 'radar',
						data: [
							{
								value: [
									_this.data.nl_sj.xin_nl,
									_this.data.nl_sj.fei_nl,
									_this.data.nl_sj.tang_nl,
									_this.data.nl_sj.zhi_nl,
									_this.data.nl_sj.gu_nl,
								],
								areaStyle: {
									normal: {
										opacity: 1,
										color: '#4472c4',
									},
								},
								lineStyle: {
									normal: {
										opacity: 1,
										color: '#4472c4',
									},
								},
								label: {
									normal: {
										show: true,
										formatter: function (params) {
											const val = params.value;
											switch (true) {
												case val >= 75:
													return 'A';
												case val >= 50:
													return 'B';
												case val >= 25:
													return 'C';
												default:
													return 'D';
											}
										},
									},
								},
							},
						],
						label: {
							color: '#000',
							fontWeight: 700,
							fontSize: 16,
						},
					},
				],
			};
			return option;
		}
		echarts.init(this.$refs.left).setOption(commonSingleOpt());
	},
	created() {
		this.filterData = filterData;
	},
	data() {
		return {
			filterData: {},
		};
	},
	methods: {
		setChartStyle(val) {
			let color = '#00b050';
			switch (true) {
				case val < 25:
					color = '#00b050';
					break;
				case val < 50:
					color = '#ffcf4b';
					break;
				case val < 75:
					color = '#ed7d31';
					break;
				default:
					color = '#fc4444';
					break;
			}
			return `background-color:${color};width:${val}%;`;
		},
	},
};
</script>

<style lang="less" scoped>
.graph-panel {
  margin-top: 30px;
}
.filter-chart {
  width: 100%;
  height: 300px;
}
.filter-right-title {
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 700;
}
.filter-body {
  display: flex;
  justify-content: center;
  .legent-list {
    margin-top: 30px;
    &-item {
      margin-top: 19px;
    }
  }
  &-right {
    margin-left: 15px;
    position: relative;
    .data-list {
      position: absolute;
      top: 34px;
      left: 2px;
      width: 100%;
      &-item {
        margin-bottom: 30px;
        display: block;
        height: 10px;
        box-shadow: 0 0 5px #382c27;
      }
    }
    .filter-pic-body {
      width: 288px;
      height: 222px;
      background: url('@/assets/img/filter-bg.png') no-repeat center / contain;
    }
    .filter-pic-footer {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
    }
  }
}
.paragraph-title {
  margin: 15px 0;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
}
.row-label {
  color: #4472c4;
  font-size: 16px;
  font-weight: 700;
}
.row-content {
  font-weight: 700;
  font-size: 16px;
}
.content-panel {
  display: flex;
  margin: 30px 0;
  align-items: center;
  &-section {
    flex: 1;
    margin: 0 5px;
    &:nth-child(1) &_title {
      background-color: #f9c002;
    }
    &:nth-child(2) &_title {
      background-color: #f0851c;
    }
    &:nth-child(3) &_title {
      background-color: #f5520b;
    }
    &:nth-child(4) &_title {
      background-color: #c50809;
    }
    &_title {
      text-align: center;
      font-size: 24px;
      color: #fff;
      height: 70px;
      line-height: 70px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    &_image {
      width: 100%;
    }
    &_list {
      background-color: #eee;
      padding: 15px 0 15px 48px;
      li {
        line-height: 35px;
        font-size: 16px;
      }
    }
  }
}
.filter-result {
  display: flex;
  margin: 50px 30px 70px;
  justify-content: space-around;
  &_field {
    color: #466db1;
    font-size: 16px;
    font-weight: 700;
  }
  &_left span:nth-child(2) {
    font-size: 24px;
    font-weight: 700;
  }
  &_right span:nth-child(2) {
    border-radius: 6px;
    background-color: #e25028;
    padding: 10px;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
  }
}
</style>
